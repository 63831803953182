import { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormApi } from 'final-form';
import { usePaymentsPerDays } from 'pages/PageDashboard/components/logic/DepositElectricityForm/hooks/usePaymentsPerDays';
import { IDepositElectricityFormValues } from 'pages/PageDashboard/components/logic/ElectricityAmountField/types/deposit-electricity-form-values.interface';
import {
  setIsSuggestedAmountActiveAction,
  setSuggestedAmountFormStateAction,
} from 'store/dashboard-reducer/dashboard.reducer';
import {
  selectSuggestedAmountActive,
  selectSuggestedAmountFormValues,
} from 'store/dashboard-reducer/dashboard.selectors';
import { useAppDispatch } from 'store/store';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './SuggestedAmountList.styled';

interface ISuggestedAmountListProps {
  isSwitcherOn: boolean;
  form: FormApi<IDepositElectricityFormValues>;
  t: (key: string) => string;
}

export const SuggestedAmountList: FC<ISuggestedAmountListProps> = ({ isSwitcherOn, t, form }) => {
  const dispatch = useAppDispatch();
  const { paymentsPerDays } = usePaymentsPerDays();
  const activeValues = useSelector(selectSuggestedAmountFormValues);
  const isActive = useSelector(selectSuggestedAmountActive);

  const handleAmountButtonClick = (usdCost: number, period: number) => {
    dispatch(setIsSuggestedAmountActiveAction(true));
    dispatch(setSuggestedAmountFormStateAction({ usdCost, period }));
    form.change('amount', isSwitcherOn ? usdCost.toString() : period.toString());
  };

  return (
    <S.SuggestedAmountsList>
      {Object.entries(paymentsPerDays).map(([amountPeriod, { period, usdCost }]) => (
        <S.SuggestedAmount key={amountPeriod}>
          <S.AmountButton
            type="button"
            variant="primary-with-children"
            onClick={() => handleAmountButtonClick(usdCost, period)}
            disabled={(isActive && activeValues && activeValues.period === period) || false}
          >
            <S.AmountValuesWrapper>
              <S.AmountPeriodValue
                $days={t('replenish.days').toLocaleLowerCase()}
              >{`${period}`}</S.AmountPeriodValue>
              <S.AmountUsdValue>${formatUsdOutput(usdCost)}</S.AmountUsdValue>
            </S.AmountValuesWrapper>
          </S.AmountButton>
        </S.SuggestedAmount>
      ))}
    </S.SuggestedAmountsList>
  );
};
