import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { TRootState } from 'store/store';
import { IFiltersState } from 'store/transaction-filters-reducer/transaction-filters.reducer';

import { ITransactionHistoryState } from './transaction-history.reducer';

export const getTransactionState = (state: TRootState): ITransactionHistoryState =>
  state.transactionHistoryReducer;

export const getFiltersState = (state: TRootState): IFiltersState =>
  state.transactionFiltersHistoryReducer;

export const selectTransactionHistory = createSelector(
  [getTransactionState, getFiltersState],
  (transactionHistoryState: ITransactionHistoryState, filters: IFiltersState) => {
    const { transactionHistoryData } = transactionHistoryState.transactions;
    return transactionHistoryData.filter((transaction) => {
      const transactionDate = dayjs(transaction.datetime);
      const matchesTransactionType =
        !filters.transactionType || transaction.category === filters.transactionType;
      const matchesStartDate =
        !filters.startDate || transactionDate.isAfter(dayjs(filters.startDate));
      const matchesEndDate =
        !filters.endDate || transactionDate.isBefore(dayjs(filters.endDate).add(1, 'day'));
      if (!filters.transactionType && filters.startDate && filters.endDate) {
        return matchesStartDate && matchesEndDate;
      } else if (filters.transactionType && !filters.startDate && !filters.endDate) {
        return matchesTransactionType;
      } else {
        return matchesTransactionType && matchesStartDate && matchesEndDate;
      }
    });
  },
);

export const selectTransactionHistoryCount = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.transactions.count,
);

export const selectTransactionHistoryStatus = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.status,
);

export const selectTransactionHistoryError = createSelector(
  [getTransactionState],
  (transactionHistoryState: ITransactionHistoryState) => transactionHistoryState.error,
);
