import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const HistorySectionTableWrapper = styled.div`
  flex: 1 0;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (max-width: ${BreakPoint.TabletTop}) {
    border-radius: 16px;
  }
`;

export const HistorySectionPlaceholderWrapper = styled(HistorySectionTableWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;
