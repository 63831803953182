import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormApi } from 'final-form';
import { DiscountView } from 'pages/PageDashboard/components/ui/DiscountView/DiscountView';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { selectSuggestedAmountFormValues } from 'store/dashboard-reducer/dashboard.selectors';
import { EPromoCodeCategories } from 'store/promocode-reducer/constants/promocode-categories';
import { setPromoCheckStatusAction } from 'store/promocode-reducer/promocode.reducer';
import {
  selectDiscountValue,
  selectPromoCheckStatus,
} from 'store/promocode-reducer/promocode.selectors';
import { checkPromoAvailabilityThunkAction } from 'store/promocode-reducer/promocode.thunk-actions';
import { useAppDispatch } from 'store/store';

import { PromoCodeArea } from 'components/ui/PromoCodeArea';
import { IPromoCodeAreaValue } from 'components/ui/PromoCodeArea/types/promo-code-area-value.interface';

import * as S from './PromoCodePortalForm.styled';

export const PromoCodePortalForm: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const discountValue = useSelector(selectDiscountValue);
  const promocodeCheckStatus = useSelector(selectPromoCheckStatus);
  const amountValues = useSelector(selectSuggestedAmountFormValues);

  const [isPortalReady, setIsPortalReady] = useState(false);
  const valueUsd = amountValues?.usdCost || 0;
  const discountUsd = (discountValue / 100) * valueUsd;
  const handlePromoCodeButtonClick = () => {
    void dispatch(setPromoCheckStatusAction(EPromoCodeStatus.Form));
  };

  const handlePromoCodeSubmit = (
    { promoCode }: IPromoCodeAreaValue,
    form: FormApi<IPromoCodeAreaValue>,
  ) => {
    if (form.getState().hasValidationErrors) return;

    void dispatch(
      checkPromoAvailabilityThunkAction({ category: EPromoCodeCategories.Electricity, promoCode }),
    );
  };

  const handlePromoCodeClose = () => {
    void dispatch(setPromoCheckStatusAction(EPromoCodeStatus.Form));
  };

  useEffect(() => {
    const portalContainer = document.getElementById('portal');
    if (!portalContainer) {
      const newPortalContainer = document.createElement('div');
      newPortalContainer.id = 'portal';
      document.body.appendChild(newPortalContainer);
    }
    setIsPortalReady(true);
  }, []);

  return (
    <div id="portal">
      {isPortalReady &&
        createPortal(
          <S.PromoCodeWrapper>
            <PromoCodeArea
              bottomBordered={false}
              promoStatus={promocodeCheckStatus}
              handlePromoCodeButtonClick={handlePromoCodeButtonClick}
              handlePromoCodeSubmit={handlePromoCodeSubmit}
              handleCloseButtonClick={handlePromoCodeClose}
              t={t}
            />
            {promocodeCheckStatus === EPromoCodeStatus.Accepted && (
              <DiscountView discount={discountValue} discountUsd={discountUsd} sumUsd={valueUsd} />
            )}
          </S.PromoCodeWrapper>,
          document.getElementById('portal') as HTMLElement,
        )}
    </div>
  );
};
