import React, { ReactElement } from 'react';

import * as S from './NavigationItem.styled';

interface INavigationItemProps {
  linkTo: string;
  text: string;
  icon: ReactElement;
  isCurrent: boolean;
  onClick?: () => void;
}

export const NavigationItem: React.FC<INavigationItemProps> = ({
  linkTo,
  text,
  icon,
  isCurrent,
  onClick,
}) => {
  return (
    <S.NavListItem as="li">
      <S.NavListLink
        linkTo={linkTo}
        text={text}
        $isCurrent={isCurrent}
        handleClick={onClick}
        icon={icon}
      />
    </S.NavListItem>
  );
};
