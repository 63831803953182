import styled from 'styled-components';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const TableWrapper = styled.div<{ isEmpty: boolean }>`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
`;

export const Tr = styled.tr`
  display: flex;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #272727;
`;

export const RowTitle = styled.td`
  width: 50%;
  color: #646464;
`;

export const RowValue = styled.td`
  width: 50%;
  color: #272727;
`;

export const Hr = styled.hr`
  margin: 0;
  opacity: 0.3;
  color: #a1a1a1;
  stroke-width: 1px;
`;

export const NoAffiliateHistory = styled(SText)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-align: center;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;
