import React from 'react';
import dayjs from 'dayjs';
import { IHistoryByDates } from 'pages/PageTransactionsHistory/types/history-by-dates.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { getTHistoryDescriptionTranslations } from 'utils/translations/translations';

import * as S from './HistoryTableDesktop.styled';

interface IDesktopTableProps {
  historyByDates: IHistoryByDates[];
  isTransactionHistoryPending: boolean;
  t: (key: string) => string;
}

export const HistoryTableDesktop: React.FC<IDesktopTableProps> = ({
  historyByDates,
  isTransactionHistoryPending,
  t,
}) => {
  return (
    <S.TableWrapper>
      {isTransactionHistoryPending ? (
        <SSpinnerBlue />
      ) : (
        <S.Table as="table">
          <thead>
            <tr>
              <th>
                <S.HeadCell>{t('transactionHistory.transactionType')}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{t('transactionHistory.reference')}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{t('transactionHistory.description')}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{t('transactionHistory.time')}</S.HeadCell>
              </th>
            </tr>
          </thead>
          <tbody>
            {historyByDates.map(({ date, historyItems }) => (
              <React.Fragment key={date}>
                <tr>
                  <td colSpan={4}>
                    <S.DateRow as="time" dateTime={new Date(date).toISOString()}>
                      {dayjs(date).format('MMMM DD, YYYY')}
                    </S.DateRow>
                  </td>
                </tr>
                {historyItems.map(({ id, category, title, datetime }) => (
                  <tr key={id}>
                    <td>
                      <S.ValueCell>
                        <S.TransactionType t={t} transactionType={category} />
                      </S.ValueCell>
                    </td>
                    <td>
                      <S.ValueCell>{id}</S.ValueCell>
                    </td>
                    <td>
                      <S.ValueCell>
                        {getTHistoryDescriptionTranslations(title, category, t)}
                      </S.ValueCell>
                    </td>
                    <td>
                      <S.TimeCell as="time" dateTime={datetime}>
                        {dayjs.utc(datetime).format('HH:mm')}
                      </S.TimeCell>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </S.Table>
      )}
    </S.TableWrapper>
  );
};
