import { FC } from 'react';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import { DISCOUNT, YOU_PAY } from './constants/ui-text.consts';

import * as S from './DiscountView.styled';

interface IDiscountViewProps {
  discount: number;
  discountUsd: number;
  sumUsd: number;
}

export const DiscountView: FC<IDiscountViewProps> = ({ discount, discountUsd, sumUsd }) => {
  return (
    <S.DiscountWrapper>
      <S.FirstLine>
        <S.Title>{DISCOUNT}</S.Title>
        <S.ValuesWrapper>
          <S.Value>${formatUsdOutput(discountUsd)}</S.Value>
          <S.Value>({discount}%)</S.Value>
        </S.ValuesWrapper>
      </S.FirstLine>
      <S.SecondLine>
        <S.Title>{YOU_PAY}</S.Title>
        <S.ValuesWrapper>
          <S.Value>${formatUsdOutput(sumUsd - discountUsd)}</S.Value>
        </S.ValuesWrapper>
      </S.SecondLine>
    </S.DiscountWrapper>
  );
};
