import { FC } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FormApi } from 'final-form';
import {
  ESwitcherElectricityStatus,
  ISuggestedAmountFormValues,
  setIsSuggestedAmountActiveAction,
  setSuggestedAmountFormStateAction,
} from 'store/dashboard-reducer/dashboard.reducer';
import {
  selectDashboardElectricity,
  selectSuggestedAmountFormValues,
  selectSwitcherUsdBtcStatus,
} from 'store/dashboard-reducer/dashboard.selectors';
import { useAppDispatch } from 'store/store';

import { formatInputValue } from 'utils/formatters/format-input-value.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isGreaterOrEqual } from 'utils/validators/is-greater-or-equal';
import { isLessOrEqual } from 'utils/validators/is-less-or-equal';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { MAXIMUM_REPLENISH_AMOUNT } from './constants/maximum-replenish-amount';
import {
  MINIMUM_REPLENISH_AMOUNT_DAYS,
  MINIMUM_REPLENISH_AMOUNT_USD,
} from './constants/minimum-replenish-amount';
import { IDepositElectricityFormValues } from './types/deposit-electricity-form-values.interface';

import * as S from './AmountField.styled';

interface IAmountFieldProps {
  activeMiners: number | null;
  submitFailed: boolean;
  isSwitcherOn: boolean;
  isReplenishPending: boolean;
  form: FormApi<IDepositElectricityFormValues>;
  t: (key: string) => string;
}

export const ElectricityAmountField: FC<IAmountFieldProps> = ({
  activeMiners,
  submitFailed,
  isReplenishPending,
  form,
  t,
}) => {
  const dispatch = useAppDispatch();
  const { dailyTotalFee } = useSelector(selectDashboardElectricity);
  const amountValues = useSelector(selectSuggestedAmountFormValues);
  const switcherStatus = useSelector(selectSwitcherUsdBtcStatus);
  const isSwitcherOn = switcherStatus === ESwitcherElectricityStatus.USD;
  const oneDayAmountValue = dailyTotalFee || 1;

  const validateMinimums = () => {
    return isSwitcherOn ? MINIMUM_REPLENISH_AMOUNT_USD : MINIMUM_REPLENISH_AMOUNT_DAYS;
  };

  const calculateUsdContent = () => {
    if (isSwitcherOn) return '';
    if (amountValues) return `$ ${formatUsdOutput(amountValues.usdCost)}`;
  };

  const calculateDaysContent = () => {
    if (!isSwitcherOn) return '';
    if (amountValues)
      return `${formatUsdOutput(amountValues.period)} ${t('replenish.days').toLocaleLowerCase()}`;
  };

  const handleInputChange = (value: string, form: FormApi<IDepositElectricityFormValues>) => {
    if (!isReplenishPending) {
      dispatch(setIsSuggestedAmountActiveAction(false));
      const formattedAmount = Math.min(
        +removeNonNumericSymbols(formatInputValue(value, 0)),
        MAXIMUM_REPLENISH_AMOUNT,
      );

      if (isSwitcherOn) {
        const usdCost = Math.ceil(formattedAmount);
        const period = +formatInputValue(usdCost / oneDayAmountValue, 0);
        dispatch(setSuggestedAmountFormStateAction({ usdCost, period }));
      } else {
        const usdCost = Math.ceil(formattedAmount * oneDayAmountValue);
        const period = +formatInputValue(usdCost / oneDayAmountValue, 0);
        dispatch(setSuggestedAmountFormStateAction({ usdCost, period }));
      }
      form.change('amount', String(formattedAmount));
    }
  };

  return (
    <Field
      name="amount"
      defaultValue="0"
      validate={composeValidators([
        isRequired,
        isNumber,
        isGreaterOrEqual(validateMinimums()),
        isLessOrEqual(MAXIMUM_REPLENISH_AMOUNT),
      ])}
    >
      {({ input, meta }) => {
        return (
          <S.InputLabel $isFailed={submitFailed}>
            <S.AmountInput
              $isSwitcherOn={isSwitcherOn}
              $usdContent={calculateUsdContent()}
              $daysContent={activeMiners ? calculateDaysContent() : ''}
              value={input.value}
              type="text"
              hasErrors={meta.error && submitFailed}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(evt.currentTarget.value, form)
              }
              autoComplete="off"
              isDisabled={isReplenishPending}
            />
            {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
          </S.InputLabel>
        );
      }}
    </Field>
  );
};
