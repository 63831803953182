import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as XCircleRedIcon } from 'assets/icons/button-icons/x-circle-red.svg';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import {
  deleteAllCartItemsThunkAction,
  deleteCartItemThunkAction,
  updateAllCartThunkAction,
  updateCartThunkAction,
} from 'store/cart-reducer/cart.thunk-actions';
import { selectCartFetchStatus } from 'store/cart-reducer/cart-selectors';
import { useAppDispatch } from 'store/store';

import { CartItem } from 'components/logic/Cart/components/ui/CartItem';
import { TTransformedCartItem } from 'components/logic/Cart/types/types';
import { mergeArraysById } from 'utils/common/arraysMergeByIdO1';
import { gtmClickCart } from 'utils/gtmSender/gtmSender';

import * as S from './CartTooltip.styled';

interface ICartTooltipProps {
  cartId: number;
  cartList: ICartItemMapped[];
  minersData: IMinerDataMapped[];
  isTooltipOpen: boolean;
  handleTooltipMouseLeave: () => void;
  handleTooltipMouseOver: () => void;
  t: (key: string) => string;
}

export const CartTooltip: FC<ICartTooltipProps> = ({
  cartId,
  cartList,
  minersData,
  isTooltipOpen,
  handleTooltipMouseLeave,
  handleTooltipMouseOver,
  t,
}) => {
  const dispatch = useAppDispatch();
  const cartFetchStatus = useSelector(selectCartFetchStatus);

  const isCartPending = cartFetchStatus === 'pending';

  const transformCartListId = cartList.map((el) => {
    const { productId, ...rest } = el;

    return {
      id: productId,
      ...rest,
    };
  });

  const cartListWithMinersData = mergeArraysById<TTransformedCartItem, IMinerDataMapped>(
    transformCartListId,
    minersData,
  );

  const handleIncrement = (id: number, quantity: number, maxQuantity: number) => {
    if (isCartPending) return;
    if (quantity >= maxQuantity) return;

    const minerDataById = minersData.find((el) => el.id === id);

    if (minerDataById) {
      const { model, id, price, hashrate, dailyElectricityFee } = minerDataById;
      gtmClickCart(model, id, price, model.split(' ')[0], hashrate, dailyElectricityFee);
    }

    void dispatch(updateCartThunkAction({ cartId, id }));
  };

  const handleDecrement = (id: number) => {
    !isCartPending && void dispatch(deleteCartItemThunkAction({ cartId, id }));
  };

  const handleDeleteAllSameItems = (id: number) => {
    if (isCartPending) return;
    const updatedCartItems = cartList
      .filter((item) => item.productId !== id)
      .map((item) => {
        const { productId, quantity } = item;

        if (productId === id)
          return {
            quantity: 0,
            product_id: item.productId,
          };
        return { product_id: productId, quantity };
      });

    void dispatch(updateAllCartThunkAction({ cartId, body: { cart_items: updatedCartItems } }));
  };

  const handleDeleteAllItems = () => {
    if (!isCartPending) {
      void dispatch(deleteAllCartItemsThunkAction({ cartId }));
    }
  };

  return (
    <S.CartTooltipWrapper
      $isVisible={isTooltipOpen}
      onMouseLeave={handleTooltipMouseLeave}
      onMouseOver={handleTooltipMouseOver}
    >
      <S.TooltipContent>
        <S.ClearAllText onClick={handleDeleteAllItems}>
          <XCircleRedIcon />
          {t('cart.clearCartText')}
        </S.ClearAllText>
        <S.CartListItems>
          {cartListWithMinersData.map((item) => (
            <CartItem
              key={item.id}
              item={item}
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              handleDeleteAll={handleDeleteAllSameItems}
            />
          ))}
        </S.CartListItems>
      </S.TooltipContent>
    </S.CartTooltipWrapper>
  );
};
