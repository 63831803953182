export const ZIndex = {
  Popup: '100',
  Menu: '10',
  Header: '5',
  DatePicker: '3',
  SelectButton: '2',
  SelectMenu: '1',
  Tooltip: '1',
  Cart: '5',
};
