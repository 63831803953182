import { ReactElement } from 'react';
import { ReactComponent as MinerIcon } from 'assets/icons/menu-icons/miner.svg';
import { ReactComponent as MinerDarkIcon } from 'assets/icons/menu-icons/miner-dark.svg';
import { ReactComponent as TransactionIcon } from 'assets/icons/menu-icons/scroll.svg';
import { ReactComponent as TransactionDarkIcon } from 'assets/icons/menu-icons/scroll-dark.svg';
import { ReactComponent as AffiliateIcon } from 'assets/icons/menu-icons/share-network.svg';
import { ReactComponent as AffiliateDarkIcon } from 'assets/icons/menu-icons/share-network-dark.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/menu-icons/squares-four.svg';
import { ReactComponent as DashboardDarkIcon } from 'assets/icons/menu-icons/squares-four-dark.svg';
import { ReactComponent as AssetsIcon } from 'assets/icons/menu-icons/table.svg';
import { ReactComponent as AssetsDarkIcon } from 'assets/icons/menu-icons/table-dark.svg';

import { AppRoute } from 'utils/route/app-route';

export interface INavItem {
  linkTo: string;
  iconLight: ReactElement;
  iconDark: ReactElement;
  text: string;
}

export const navItems: INavItem[] = [
  {
    linkTo: AppRoute.Home(),
    text: 'Dashboard',
    iconLight: <DashboardIcon />,
    iconDark: <DashboardDarkIcon />,
  },
  {
    linkTo: AppRoute.Purchase(),
    text: 'buyAMiner',
    iconLight: <MinerIcon />,
    iconDark: <MinerDarkIcon />,
  },
  {
    linkTo: AppRoute.Assets(),
    text: 'Assets',
    iconLight: <AssetsIcon />,
    iconDark: <AssetsDarkIcon />,
  },
  {
    linkTo: AppRoute.TransactionsHistory(),
    text: 'transactionHistory',
    iconLight: <TransactionIcon />,
    iconDark: <TransactionDarkIcon />,
  },
  {
    linkTo: AppRoute.AffiliateProgram(),
    text: 'affiliateProgram',
    iconLight: <AffiliateIcon />,
    iconDark: <AffiliateDarkIcon />,
  },
];
