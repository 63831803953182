import { css, styled } from 'styled-components';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { EShadows } from 'styles/style-variables/shadows';

import { SText } from 'components/styled/SText';

export const CartTooltipWrapper = styled.div<{ $isVisible: boolean }>`
  z-index: -1;
  display: flex;
  flex-direction: column;
  opacity: 1;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      opacity: 0;

      * {
        cursor: default;
        pointer-events: none;
      }
    `}
  transition: opacity ${AnimationDuration.TooltipOpening};
  pointer-events: all;
`;

export const TooltipContent = styled.div`
  position: absolute;
  right: -16px;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${EColors.BlueGrayBackMid};
  border-radius: 24px;
  background: ${EColors.White};
  box-shadow: ${EShadows.basic};

  @media (min-width: ${BreakPoint.TabletTop}) {
    right: -24px;
    padding: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    position: fixed;
    right: 16px;
    bottom: 120px;
  }
`;

export const ClearAllText = styled(SText)`
  display: flex;
  align-items: center;
  gap: 2px;
  width: max-content;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Red1};
  cursor: pointer;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const CartListItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
