import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const InputLabel = styled.label<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }}
`;

export const AmountInput = styled(Input)<{
  $isSwitcherOn?: boolean;
  $usdContent?: string;
  $daysContent?: string;
}>`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #a1a1a1;

  & input {
    padding: ${({ $isSwitcherOn }) => ($isSwitcherOn ? '12px 12px 12px 27px' : '12px')};
  }

  &::before {
    content: ${({ $isSwitcherOn }) => ($isSwitcherOn ? "'$'" : "''")};
    position: ${({ $isSwitcherOn }) => ($isSwitcherOn ? 'absolute' : 'static')};
    top: 50%;
    left: ${({ $isSwitcherOn }) => ($isSwitcherOn ? '12px' : '0')};
    transform: ${({ $isSwitcherOn }) => ($isSwitcherOn ? 'translateY(-50%)' : 'none')};
  }

  &::after {
    content: ${({ $isSwitcherOn, $daysContent, $usdContent }) =>
      $isSwitcherOn ? `'${$daysContent}'` : `'${$usdContent}'`};
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;
