import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: 24px;
`;

export const ButtonsWrapper = styled(Container)`
  max-width: 160px;
`;

export const AddToCartButton = styled(Button)`
  gap: 8px;
  width: 100%;
  padding: 12px 16px;
  border-radius: inherit;
  font-size: 14px;
  line-height: 18px;

  & svg path:nth-child(3) {
    fill: transparent;
  }
`;

export const OutOfStockBadge = styled(SText)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border-radius: inherit;
  background-color: #c9c9c9;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  user-select: none;

  & svg {
    flex-shrink: 0;

    & path {
      fill: #fff;
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
    padding: 7px 12px;
  }
`;

export const CounterButton = styled(Button)`
  width: 50%;
  min-height: 42px;
  padding: 10px;

  &:first-of-type {
    border-radius: 24px 0 0 24px;
  }

  &:last-of-type {
    border-radius: 0 24px 24px 0;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    min-height: 32px;
    padding: 8px;
  }
`;

export const CounterInput = styled.input<{ $backgroundColor: EColors }>`
  width: 100%;
  padding: 12px;
  border: none;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  transition: background-color ${TransitionTime.Hover};
  outline: none;
  user-select: none;
  font-variant-numeric: tabular-nums;
`;

export const QuantityValue = styled(SText)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding: 12px;
  background-color: ${EColors.Yellow3};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  user-select: none;
  font-variant-numeric: tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;
