import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUptime } from 'hooks/useUptime';
import {
  CooldownExplainer,
  ECooldownType,
} from 'pages/PageAssets/components/logic/CooldownExplainer/CooldownExplainer';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';

import { Switcher } from 'components/ui/Switcher';
import { ESwitcherSize } from 'components/ui/Switcher/types/switcher-props';

import * as S from './LimitedUptimeExplainer.styled';

interface ILimitedUptimeExplainerProps {
  isInAssets?: boolean;
}

export const LimitedUptimeExplainer: React.FC<ILimitedUptimeExplainerProps> = ({ isInAssets }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    cooldown,
    uptime,
    lastUptimeToggle,
    uptimeStatus,
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
  } = useUptime();

  const isLoading = uptimeStatus === 'pending';

  const handleChange = () => {
    if (uptime) {
      void handleLimitedUptimeDisabled();
    } else {
      void handleLimitedUptimeEnabled();
    }
  };

  useEffect(() => {
    if (!lastUptimeToggle) {
      void dispatch(requestUptimeThunkAction());
    }
  }, [dispatch, lastUptimeToggle]);

  return (
    <S.ExplainerArticle>
      {!isInAssets && (
        <>
          <S.SwitcherWrapper>
            <S.LeftColumn>
              <S.UptimeTextLarge>{t('uptime.100uptime')}</S.UptimeTextLarge>
              <S.UptimeText>{t('uptime.higherElectricityFee')}</S.UptimeText>
            </S.LeftColumn>
            <Switcher
              size={ESwitcherSize.medium}
              checked={uptime}
              onChange={handleChange}
              disabled={isLoading || cooldown}
            />
            <S.RightColumn>
              <S.UptimeTextLarge>{t('uptime.65uptime')}</S.UptimeTextLarge>
              <S.UptimeText>{t('uptime.lowerElectricityFee')}</S.UptimeText>
            </S.RightColumn>
          </S.SwitcherWrapper>
          {cooldown && (
            <CooldownExplainer
              cooldownType={ECooldownType.inModal}
              lastUptimeToggle={lastUptimeToggle}
            />
          )}
          <S.Tittle>{t('uptime.how65PercentUptimeWorks')}</S.Tittle>
        </>
      )}
      <S.ExplainerParagraphsWrapper>
        <S.ExplainerParagraph>{t('uptime.peakHoursOptimization')}</S.ExplainerParagraph>
        <S.ExplainerParagraph>{t('uptime.minersSchedule')}</S.ExplainerParagraph>
        <S.ExplainerParagraph>{t('uptime.incomeImpact')}</S.ExplainerParagraph>
      </S.ExplainerParagraphsWrapper>
    </S.ExplainerArticle>
  );
};
