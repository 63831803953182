import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackPromocodeMessages } from 'constants/notification-messages/fallback-promocode-messages';
import {
  applyPromocode,
  checkPromocodeAvailability,
} from 'store/api/cuverse-api/promocode/promocode.service';
import { IPromocodeError } from 'store/api/cuverse-api/promocode/types/coupon-error.interface';
import {
  IPromocodeCheckData,
  IPromocodeCheckDataMapped,
  IPromocodeCheckResponse,
  IPromocodeResponse,
  IPromocodeResponseDataMapped,
} from 'store/api/cuverse-api/promocode/types/promocode-response.interface';

import { mapCouponCheckData, mapCouponData } from 'utils/mapper/mapper';
import { notifySuccess } from 'utils/notify/notify.utils';

import { EPromoCodeCategories } from './constants/promocode-categories';
import { errorsPresent } from './utils/errors-present';

interface ICheckPromo {
  category: EPromoCodeCategories;
  promoCode: string;
}

interface IApplyPromo {
  category: EPromoCodeCategories;
  purchaseId?: number;
  promoCode: string;
}

export const checkPromoAvailabilityThunkAction = createAsyncThunk<
  IPromocodeCheckDataMapped,
  ICheckPromo
>('promocode/checkPromoAvailability', async ({ category, promoCode }) => {
  return await checkPromocodeAvailability({
    coupon_type: category,
    coupon_code: promoCode,
  })
    .then((response: IPromocodeCheckResponse) => {
      return mapCouponCheckData(response.data);
    })
    .catch((error: AxiosError<IPromocodeError>) => {
      throw error;
    });
});

export const applyPromoThunkAction = createAsyncThunk<IPromocodeResponseDataMapped, IApplyPromo>(
  'promocode/applyPromo',
  async ({ category, purchaseId = null, promoCode }) => {
    return await applyPromocode({
      coupon_type: category,
      purchase_id: purchaseId,
      coupon_code: promoCode,
    })
      .then((response: IPromocodeResponse) => {
        notifySuccess(FallbackPromocodeMessages.PromoCodeAccepted);
        return mapCouponData(response.data);
      })
      .catch((error: AxiosError<IPromocodeError>) => {
        throw error;
      });
  },
);
