import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/button-icons/miner-white.svg';
import { ReactComponent as CaretRight } from 'assets/icons/dashboard-page-icons/caret-right.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/dashboard-page-icons/invoice.svg';
import dayjs from 'dayjs';
import { useInnerWidth } from 'hooks/useInnerWidth';
import {
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
} from 'pages/PageTransactionsHistory/constants/constants';
import { useAppDispatch } from 'store/store';
import {
  selectTransactionHistory,
  selectTransactionHistoryCount,
  selectTransactionHistoryStatus,
} from 'store/transaction-reducer/transaction-history.selectors';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SPageSection } from 'components/styled/SPageSection';
import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { ContentPlaceholder } from 'components/ui/ContentPlaceholder';
import { TransactionTypeBadge } from 'components/ui/TransactionTypeBadge';
import { getSlicedItemsWithoutIncorrectCategories } from 'utils/common/get-historyItems.utils';
import { AppRoute } from 'utils/route/app-route';
import { getTHistoryDescriptionTranslations } from 'utils/translations/translations';

import { LIST_ROW_HEIGHT_WITH_GAP, MAX_DISPLAYING_ROW_MOBILE } from './constants/row-height';

import * as S from './HistorySection.styled';

export const HistorySection: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const transactionHistory = useSelector(selectTransactionHistory);
  const transactionHistoryCount = useSelector(selectTransactionHistoryCount);
  const transactionHistoryStatus = useSelector(selectTransactionHistoryStatus);
  const { innerWidth } = useInnerWidth();
  const listRef = useRef<HTMLDListElement | null>(null);
  const [visibleRows, setVisibleRows] = useState<number>(0);
  const { slicedItems } = getSlicedItemsWithoutIncorrectCategories(
    transactionHistory,
    0,
    visibleRows,
  );

  const isMobile = innerWidth <= +BreakPoint.MobileTop.replace('px', '');
  const isPending = transactionHistoryStatus === 'pending';
  const isInitial = transactionHistoryStatus === 'initial';
  const calculateVisibleRows = useCallback(() => {
    if (isMobile) return;
    if (listRef.current) {
      const containerHeight = listRef.current.getBoundingClientRect().height;
      const rows = Math.ceil(containerHeight / LIST_ROW_HEIGHT_WITH_GAP);
      setVisibleRows(rows);
    }
  }, [isMobile]);

  useEffect(() => {
    void dispatch(
      requestTransactionHistoryData({ count: HISTORY_TRANSACTIONS_COUNT, dateStart: INITIAL_DATE }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      setVisibleRows(MAX_DISPLAYING_ROW_MOBILE);
    }

    calculateVisibleRows();
  }, [calculateVisibleRows, isMobile, transactionHistoryStatus]);

  if (transactionHistoryCount === 0) {
    if (isPending) {
      return (
        <SPageSection tittleCeo={t('history.latestTransactionsHistory')}>
          <S.HistoryArticle $isEmpty={true}>
            <SSpinnerBlue />
          </S.HistoryArticle>
        </SPageSection>
      );
    }
    return (
      <SPageSection tittleCeo={t('history.latestTransactionsHistory')}>
        <S.HistoryArticle $isEmpty={!transactionHistory.length}>
          <ContentPlaceholder
            buttonIcon={<MinerWhiteIcon />}
            buttonText={t('contentPlaceholder.buyMiner')}
            tittleText={t('contentPlaceholder.buyYourFirstMiner')}
            onButtonClick={() => navigate(AppRoute.Purchase())}
          />
        </S.HistoryArticle>
      </SPageSection>
    );
  }

  return (
    <SPageSection tittleCeo={t('history.latestTransactionsHistory')}>
      <S.HistoryArticle $isEmpty={false}>
        <S.Title as="h3">
          <InvoiceIcon />
          {t('history.recentTransactions')}
        </S.Title>
        <S.HistoryLink to={AppRoute.TransactionsHistory()}>
          {t('history.viewAllTransactions')}
          <CaretRight />
        </S.HistoryLink>
        <S.TransactionsList as="dl" ref={listRef}>
          {isPending ? (
            <SSpinnerBlue />
          ) : (
            slicedItems?.map(({ category, title, datetime, id }) => (
              <S.ListRow key={id}>
                <S.TransactionType as="dt">
                  <TransactionTypeBadge t={t} transactionType={category} />
                </S.TransactionType>
                <S.TransactionDate as="dd">{`${dayjs.utc(datetime).format('DD.MM.YYYY HH:mm')}`}</S.TransactionDate>
                <S.TransactionMessage as="dd">
                  {getTHistoryDescriptionTranslations(title, category, t)}
                </S.TransactionMessage>
              </S.ListRow>
            ))
          )}
        </S.TransactionsList>
      </S.HistoryArticle>
    </SPageSection>
  );
};
