import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/button-icons/miner-white.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { FiltersPanel } from 'pages/PageTransactionsHistory/components/logic/FiltersPanel';
import { HistoryTableDesktop } from 'pages/PageTransactionsHistory/components/ui/HistoryTableDesktop';
import { HistoryTableMobile } from 'pages/PageTransactionsHistory/components/ui/HistoryTableMobile';
import {
  HISTORY_ITEMS_PER_PAGE,
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
} from 'pages/PageTransactionsHistory/constants/constants';
import { groupHistoryItemsByDates } from 'pages/PageTransactionsHistory/utils/group-history-items-by-dates.util';
import { ITransaction } from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';
import { useAppDispatch } from 'store/store';
import {
  selectTransactionHistory,
  selectTransactionHistoryCount,
  selectTransactionHistoryStatus,
} from 'store/transaction-reducer/transaction-history.selectors';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SPageSection } from 'components/styled/SPageSection';
import { ContentPlaceholder } from 'components/ui/ContentPlaceholder';
import { Pagination } from 'components/ui/Pagination';
import { getSlicedItemsWithoutIncorrectCategories } from 'utils/common/get-historyItems.utils';
import { AppRoute } from 'utils/route/app-route';

import * as S from './HIstorySection.styled';

interface IProps {
  t: (key: string) => string;
}

export const HistorySection: React.FC<IProps> = ({ t }) => {
  const dispatch = useAppDispatch();

  const transactionHistory = useSelector(selectTransactionHistory);
  const transactionHistoryCount = useSelector(selectTransactionHistoryCount);
  const navigate = useNavigate();
  const transactionHistoryStatus = useSelector(selectTransactionHistoryStatus);
  const isTransactionHistoryPending = transactionHistoryStatus === 'pending';
  const { innerWidth } = useInnerWidth();
  const historyItems: ITransaction[] = transactionHistory;

  const [currentPage, setCurrentPage] = useState(1);

  const { currentHistoryItems, slicedItems } = getSlicedItemsWithoutIncorrectCategories(
    historyItems,
    (currentPage - 1) * HISTORY_ITEMS_PER_PAGE,
    currentPage * HISTORY_ITEMS_PER_PAGE,
  );

  const displayedHistory = groupHistoryItemsByDates(slicedItems);

  const totalPages = Math.ceil(currentHistoryItems?.length / HISTORY_ITEMS_PER_PAGE);
  const handleSetCurrentPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    void dispatch(
      requestTransactionHistoryData({ count: HISTORY_TRANSACTIONS_COUNT, dateStart: INITIAL_DATE }),
    );
  }, [dispatch]);

  if (transactionHistoryCount === 0 && !isTransactionHistoryPending) {
    return (
      <SPageSection>
        <S.HistorySectionPlaceholderWrapper>
          <FiltersPanel setCurrentPage={setCurrentPage} />
          <ContentPlaceholder
            buttonIcon={<MinerWhiteIcon />}
            buttonText={t('contentPlaceholder.buyMiner')}
            tittleText={t('contentPlaceholder.buyYourFirstMiner')}
            onButtonClick={() => navigate(AppRoute.Purchase())}
          />
        </S.HistorySectionPlaceholderWrapper>
      </SPageSection>
    );
  }

  return (
    <SPageSection tittleCeo="Transactions history table">
      <S.HistorySectionTableWrapper>
        <FiltersPanel setCurrentPage={setCurrentPage} />
        {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
          <HistoryTableDesktop
            historyByDates={displayedHistory}
            isTransactionHistoryPending={isTransactionHistoryPending}
            t={t}
          />
        ) : (
          <HistoryTableMobile
            historyByDates={displayedHistory}
            isTransactionHistoryPending={isTransactionHistoryPending}
            t={t}
          />
        )}
      </S.HistorySectionTableWrapper>
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handleSetCurrentPage={handleSetCurrentPage}
          t={t}
        />
      )}
    </SPageSection>
  );
};
