import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { MenuWidth } from 'styles/style-variables/menu-width';
import { EShadows } from 'styles/style-variables/shadows';
import { ZIndex } from 'styles/style-variables/z-index';

import { SText } from 'components/styled/SText';

export const FixedCartBlock = styled.section<{ $isEmptyCart: boolean; $sideSpace: number }>`
  position: fixed;
  right: ${({ $sideSpace }) => `${$sideSpace}px`};
  bottom: 30px;
  left: ${({ $sideSpace }) => `${$sideSpace}px`};
  z-index: ${ZIndex.Cart};
  max-width: calc(100% - ${({ $sideSpace }) => `${$sideSpace * 2}px`});
  margin: 0 24px;

  @media (min-width: ${BreakPoint.DesktopTop}) {
    right: ${({ $sideSpace }) => `${$sideSpace}px`};
    left: ${({ $sideSpace }) => `${parseInt(MenuWidth.Desktop) + $sideSpace}px`};
  }

  @media (max-width: ${BreakPoint.DesktopTop}) {
    right: 0;
    left: ${parseInt(MenuWidth.Desktop)}px;
  }

  @media (max-width: ${BreakPoint.LaptopTop}) {
    right: 0;
    left: 0;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    margin: 0 16px;
  }
`;

export const CartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 24px;
  background-color: ${EColors.White};
  box-shadow: ${EShadows.basic};

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
  }
`;

export const Text = styled(SText)`
  padding: 7px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: ${EColors.Gray3};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;
