import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormApi } from 'final-form';
import { ElectricityAmountField } from 'pages/PageDashboard/components/logic/ElectricityAmountField/AmountField';
import { IDepositElectricityFormValues } from 'pages/PageDashboard/components/logic/ElectricityAmountField/types/deposit-electricity-form-values.interface';
import { SuggestedAmountList } from 'pages/PageDashboard/components/ui/SuggestedAmountList';
import {
  ESwitcherElectricityStatus,
  setIsSuggestedAmountActiveAction,
  setSuggestedAmountFormStateAction,
  setSwitcherUsdBtcStatusAction,
} from 'store/dashboard-reducer/dashboard.reducer';
import {
  selectActiveMiners,
  selectSuggestedAmountFormValues,
  selectSwitcherUsdBtcStatus,
} from 'store/dashboard-reducer/dashboard.selectors';
import { EPaymentCategory, setPaymentCategoryAction } from 'store/payment-reducer/payment.reducer';
import { EPromoCodeStatus } from 'store/promocode-reducer/constants/promocode-statuses';
import { setPromoCheckStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectPromocodeId } from 'store/promocode-reducer/promocode.selectors';
import { selectElectricityReplenishFetchStatus } from 'store/replenish-reducer/replenish.selectors';
import { fetchReplenishThunkAction } from 'store/replenish-reducer/replenish.thunk-actions';
import { useAppDispatch } from 'store/store';

import { Switcher } from 'components/ui/Switcher';
import { ESwitcherSize, ESwitcherVariants } from 'components/ui/Switcher/types/switcher-props';
import { gtmClickReplenish } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

import { PromoCodePortalForm } from './components/PromoCodePortalForm';
import { SWITCHER_SECOND_TITTLE } from './constants/ui-text.const';

import * as S from './DepositElectricityForm.styled';

interface IProps {
  t: (key: string) => string;
}

export const DepositElectricityForm: React.FC<IProps> = ({ t }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const replenishFetchStatus = useSelector(selectElectricityReplenishFetchStatus);
  const activeMiners = useSelector(selectActiveMiners);
  const couponId = useSelector(selectPromocodeId);
  const amountValues = useSelector(selectSuggestedAmountFormValues);
  const switcherStatus = useSelector(selectSwitcherUsdBtcStatus);

  const isSwitcherOn = switcherStatus === ESwitcherElectricityStatus.USD;
  const isReplenishPending = replenishFetchStatus === 'pending';

  const handleSwitchChange = (form: FormApi<IDepositElectricityFormValues>) => {
    if (isSwitcherOn) {
      dispatch(setSwitcherUsdBtcStatusAction(ESwitcherElectricityStatus.DAYS));
      if (amountValues) {
        form.change('amount', amountValues.period.toString());
      }
    } else {
      dispatch(setSwitcherUsdBtcStatusAction(ESwitcherElectricityStatus.USD));
      if (amountValues) {
        form.change('amount', amountValues.usdCost.toString());
      }
    }
  };
  const handleFormSubmit = async (
    _: IDepositElectricityFormValues,
    form: FormApi<IDepositElectricityFormValues>,
  ) => {
    if (isReplenishPending) return;
    if (form.getState().hasValidationErrors) return;

    const amount = amountValues?.usdCost.toString();
    if (!amount) return;

    await dispatch(fetchReplenishThunkAction({ amount, coupon_id: couponId }));
    dispatch(setPaymentCategoryAction(EPaymentCategory.Electricity));
    navigate(AppRoute.Payment());
    gtmClickReplenish(amount);
  };

  useEffect(() => {
    if (!activeMiners) {
      dispatch(setSwitcherUsdBtcStatusAction(ESwitcherElectricityStatus.USD));
    }
  }, [dispatch, activeMiners]);

  useEffect(() => {
    return () => {
      dispatch(setSuggestedAmountFormStateAction({ usdCost: 0, period: 0 }));
      dispatch(setIsSuggestedAmountActiveAction(false));
      dispatch(setSwitcherUsdBtcStatusAction(ESwitcherElectricityStatus.DAYS));
      void dispatch(setPromoCheckStatusAction(EPromoCodeStatus.Button));
    };
  }, [dispatch]);

  return (
    <S.DepositElectricityWrapper>
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, submitFailed, form, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <S.FormContent>
                {activeMiners && (
                  <S.SuggestedAmountsBlock>
                    <S.SuggestedAmountTitle>{t('replenish.selectAmount')}</S.SuggestedAmountTitle>
                    <SuggestedAmountList isSwitcherOn={isSwitcherOn} form={form} t={t} />
                  </S.SuggestedAmountsBlock>
                )}
                <S.AnotherAmountBlock>
                  <S.SwitcherWrapper>
                    <S.LabelText>
                      {activeMiners
                        ? t('replenish.selectAnotherAmount')
                        : t('replenish.selectAmount')}
                    </S.LabelText>
                    {activeMiners && (
                      <Switcher
                        leftTittle={t('replenish.days')}
                        rightTittle={SWITCHER_SECOND_TITTLE}
                        checked={isSwitcherOn}
                        onChange={() => handleSwitchChange(form)}
                        variant={ESwitcherVariants.withTittle}
                        size={ESwitcherSize.small}
                      />
                    )}
                  </S.SwitcherWrapper>
                  <S.FormInputsWrapper $submitFailed={submitFailed && hasValidationErrors}>
                    <ElectricityAmountField
                      activeMiners={activeMiners}
                      submitFailed={submitFailed}
                      isSwitcherOn={isSwitcherOn}
                      isReplenishPending={isReplenishPending}
                      form={form}
                      t={t}
                    />
                    <S.DepositButtonWrapper>
                      <PromoCodePortalForm />
                      <S.DepositButton
                        onClick={() => handleFormSubmit(form.getState().values, form)}
                        text={t('replenish.replenishButtonText')}
                        type="submit"
                        disabled={(submitFailed && hasValidationErrors) || isReplenishPending}
                      />
                    </S.DepositButtonWrapper>
                  </S.FormInputsWrapper>
                </S.AnotherAmountBlock>
              </S.FormContent>
            </form>
          );
        }}
      />
    </S.DepositElectricityWrapper>
  );
};
